import React from "react"

import Layout from "../components/0_utilities/layout"
import SEO from "../components/0_utilities/seo"

import ServicesImg from "../components/1_atoms/banner/img/img-services"

import Action from "../components/1_atoms/images/services/action"
import Cleaning from "../components/1_atoms/images/services/cleaning"
import Climate from "../components/1_atoms/images/services/climate"
import Repairs from "../components/1_atoms/images/services/repairs"
import Tuning from "../components/1_atoms/images/services/tuning"

const allServices = [
  {
    title: "Tuning",
    description:
      "Pianos require tuning at least twice a year. If your piano hasn’t been tuned in years, it may need some extra work but they usually can be brought back up to pitch!",
    img: <Tuning />,
  },
  {
    title: "Repairs",
    description:
      "Does your piano have broken strings, keys, casters, pedals, cracked soundboard, mouse damage ect…?  Most things that break or wear out on a piano can be fixed!",
    img: <Repairs />,
  },
  {
    title: "Action Regulation",
    description:
      "A piano action consists of thousands of parts made of wood, felt, leather and metal. Over time these parts will wear, loosen, become misaligned and need periodic adjusting. A well regulated piano will feel even to the touch, minimize unwanted mechanical sounds, allow you to play more expressively and extend it’s life expectancy.  When was your piano last regulated?",
    img: <Climate />,
  },
  {
    title: "Cleaning",
    description:
      "Pianos collect dust and dirt.  It is important that they be cleaned by a piano service professional.",
    img: <Cleaning />,
  },
  {
    title: "Climate Control Systems",
    description:
      "Providing a stable climate for you piano will greatly improve tuning stability and performance in addition to extending its life expectancy.",
    img: <Action />,
  },
]

const IndexPage = () => (
  <Layout image={<ServicesImg />} pageTitle="Services">
    <SEO
      title="Service Provided"
      description="Jason Klinke is a Philadelphia-South Jersey area piano
      tuner/technician and certified climate control specialist. With nearly
      twenty years experience, Jason has tuned, repaired, and rebuilt countless
      pianos. With a passion for pianos and strong work ethic, Jason’s primary
      goal is providing a professional and affordable, quality service that you
      can trust."
    />
    <p>
      <strong>Jason Klinke</strong> is a Philadelphia-South Jersey area piano
      tuner/technician and certified climate control specialist. With nearly
      twenty years experience, Jason has tuned, repaired, and rebuilt countless
      pianos. With a passion for pianos and strong work ethic, Jason’s primary
      goal is providing a professional and affordable, quality service that you
      can trust.
    </p>
    <br />
    <div className="mt-8">
      <div>
        <h2>Who does Jason Service?</h2>
        <p>
          Private Residences, Recording Studios, Church’s/Centers of Worship,
          Restaurants, Schools, Colleges, University, Night Clubs/Music Venues.
        </p>
      </div>
      <br />
      <div>
        <h2>Service area includes:</h2>
        <p>
          <strong>South Jersey:</strong> Atlantic County, Burlington County,
          Camden County, Gloucester County, Salem County.
          <br />
          <br />
          <strong>Philadelphia area:</strong> Delaware County, Chester County,
          Montgomery County, Bucks County.
        </p>
        <br />
      </div>
    </div>
    <h2>Services includes:</h2>
    {allServices.map((service, i) => {
      return (
        <div className="my-8" key={i}>
          <div className="lg:flex">
            <div className="flex justify-start">
              <div style={{ width: 200 }}>{service.img}</div>
            </div>
            <div className="flex flex-col justify-center py-4 lg:px-8">
              <h3 className="m-0">{service.title}</h3>
              <p className="mt-4 ">{service.description}</p>
            </div>
          </div>
        </div>
      )
    })}
  </Layout>
)

export default IndexPage
